export enum CONTACTS_VIEW_TYPES {
  GRID = "GRID",
  KANBAN = "KANBAN",
}

export enum APP_LANGUAGES {
  VN = "vi-VN",
  US = "en-US",
  ZH = "zh-CN",
  KM = "km-KH",
}

export enum ACCOUNT_ROLE {
  ADMIN = "admin",
}

export enum SMS_CAMPAIGNS_TYPES {
  ADV = "ADV",
  CS = "CS",
}

export enum CUSTOMER_TYPE {
  COMPANY = "COMPANY",
  PERSON = "PERSON",
}

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_URL: "redirect_url",
  CHECKING_PERMISSION: "checking_permission",
  API_KEY: "api_key",
  ACCESS: "access",
  REFRESH: "refresh",
  PREFERRED_ENVIRONMENT: "preferred_environment",
};

export const FORMAT_TYPE_DATE = "DD/MM/YYYY";

export enum DATA_TYPE {
  DATE = "DATE",
  STRING = "STRING",
}

export enum TYPE_MESSAGE {
  ZNS = "ZNS",
  SMS = "SMS",
}

export const GlobalBorderRadiusPx = "12px";

export enum MESSAGE_STATUS {
  success = "SUCCESS",
  fail = "FAIL",
}

export const QUERY_MAXIMUM_RECORDS = {
  page: 1,
  page_size: 9_999_999,
};

export enum ProductSlugEnum {
  product_code = "product_code",
  product_name = "product_name",
  product_price = "product_price",
  product_description = "product_description",
  product_cost = "product_cost",
  product_price_difference = "product_price_difference",
  product_images = "product_images",
  product_payment_cycle = "product_payment_cycle",
  product_category = "product_category",
  changed_by = "changed_by",
  created_by = "created_by",
}

export enum INPUT_TYPE_KEY {
  formulaproperty = "formulaproperty",
  singlelineproperty = "singlelineproperty",
  multilineproperty = "multilineproperty",
  richtextproperty = "richtextproperty",
  integerproperty = "integerproperty",
  floatproperty = "floatproperty",
  dateproperty = "dateproperty",
  singleoptionproperty = "singleoptionproperty",
  multioptionproperty = "multioptionproperty",
  singleuserproperty = "singleuserproperty",
  multiuserproperty = "multiuserproperty",
  associateproperty = "associateproperty",
  phoneproperty = "phoneproperty",
  fileproperty = "fileproperty",
}

export enum TYPE_NOTIFICATION {
  OTHER = "OTHER",
  ASSIGN = "ASSIGN", // phân công
  UPDATE = "UPDATE", // cập nhật
  MESSAGE = "MESSAGE", // tin nhắn
  EXPIRE = "EXPIRE", // hết hạn
  MISS_CALL = "MISS_CALL", // gọi nhỡ
  SYSTEM = "SYSTEM", // hệ thống
  UPLOAD = "UPLOAD", // tải lên
  DOWNLOAD = "DOWNLOAD", // tải xuống
  PAYMENT = "PAYMENT",
}

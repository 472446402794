"use client";

import React, { useContext, useMemo } from "react";
import {
  ENV_CONFIGS,
  ENVIRONMENTS,
} from "@/providers/app/Environment/environments";
import { LOCAL_STORAGE_KEYS } from "@/utils/app/constants";
import { useLocalStorage } from "usehooks-ts";

interface EnvironmentProviderProps {
  children: React.ReactNode;
}

interface EnvironmentContextState {
  API_URL: string;
  NEXT_API_URL: string;
  FACEBOOK_APP_ID: string;
  ZALO_REDIRECT_URL: string;
  ZALO_APP_ID: string;
  APP_V2_URL: string;
  SHOW_DEV_MENU: boolean;
  PHONE_IFRAME_SYNC_SPEED: number;
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
  FIREBASE_VAPID_KEY: string;
  currentEnvironment: ENVIRONMENTS;
  setRuntimeEnvironment: (env: ENVIRONMENTS) => void;
  FEATURE_FLAGS: {
    OMNI_CHANNEL_CHAT: boolean;
    OMNI_CHANNEL_ZALO_PERSONAL: boolean;
  };
}

const EnvironmentContext = React.createContext<
  EnvironmentContextState | undefined
>(undefined);

export const EnvironmentProvider = ({ children }: EnvironmentProviderProps) => {
  const [_runtimeEnvironment, setRuntimeEnvironment] =
    useLocalStorage<ENVIRONMENTS>(
      LOCAL_STORAGE_KEYS.PREFERRED_ENVIRONMENT,
      process.env.NEXT_PUBLIC_RUNTIME_ENVIRONMENT
        ? (process.env.NEXT_PUBLIC_RUNTIME_ENVIRONMENT as ENVIRONMENTS)
        : ENVIRONMENTS.LOCAL,
      {
        initializeWithValue: false,
      }
    );

  const runtimeEnvironment = useMemo(() => {
    if (process.env.NODE_ENV === "production") {
      return process.env.NEXT_PUBLIC_RUNTIME_ENVIRONMENT as ENVIRONMENTS;
    }
    return _runtimeEnvironment;
  }, [_runtimeEnvironment]);

  const injectedEnvironment = useMemo(() => {
    if (runtimeEnvironment === ENVIRONMENTS.LOCAL) {
      return ENV_CONFIGS.LOCAL;
    }
    if (runtimeEnvironment === ENVIRONMENTS.STAG) {
      return ENV_CONFIGS.STAG;
    }
    if (runtimeEnvironment === ENVIRONMENTS.PROD) {
      return ENV_CONFIGS.PROD;
    }
    return ENV_CONFIGS["DEV"];
  }, [runtimeEnvironment]);

  const setAndSaveRuntimeEnvironment = (env: ENVIRONMENTS) => {
    setRuntimeEnvironment(env);
  };

  return (
    <EnvironmentContext.Provider
      value={{
        ...injectedEnvironment,
        currentEnvironment: runtimeEnvironment,
        setRuntimeEnvironment: setAndSaveRuntimeEnvironment,
      }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export function useEnvironment(): EnvironmentContextState {
  const context = useContext<any>(EnvironmentContext);

  if (!context) {
    throw Error("useEnvironment must be used within a EnvironmentProvider");
  }
  return context;
}
